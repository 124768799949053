import {Button} from "@mui/material";
import EditFormDrawer from "components/custom/EditFormDrawer";
import React, {useState} from "react";
import {toast} from "react-toastify";
import config from "config";
import network from "network";
import {useParams} from "react-router-dom";

export default function CreateRoom() {
    const [roomObject, setRoomObject] = useState(null)
    const params = useParams()

    async function openForm() {
        const obj = {
            title: '',
            description: '',
            slug: '',
            image_url: '',
            commission: 0,
            total_seats: 0,
            brand_partner: '',
            allowed_publication_type: 'story',
            instructions: [],
            project_brief: {desc: [], url: ''}
        }
        setRoomObject(obj)
    }

    const handleSubmit = async (mainData) => {
        let res = await network.post(
            `${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success("New room created successfully")
        } else
            toast.error(res.data.message, {autoClose: 2000})
    }

    return <>
        <Button variant={"outlined"} onClick={() => openForm()}>+Create New Room</Button>
        {!!roomObject && <EditFormDrawer onClose={() => setRoomObject(null)} open={!!roomObject} schema={roomObject}
                                         onSave={handleSubmit}/>}
    </>
}
