import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import config from "config";
import network from "network";
import DialogBox from 'components/custom/dialog-box'
import BasePage from "components/BasePage";
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import EditUserInfo from 'components/community/EditUserInfo';
import {Paper} from '../../../node_modules/@mui/material/index';
import {useUser} from "../../components/UserProvider";
import UserScopeDialog from "../../components/userscope-dialog";

export default function UserProfile() {
    const [data, setData] = useState<{
        id,
        name: { first: "...", last: "..." }
        username
        _id
        email
        phone_number
        about
        response_time
        gender
        scope
    }>()
    const [dialogBoxMessage, setDialogBoxMessage] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const [editUser, setEditUser] = useState(false);
    const [openUserScope, setOpenUserScope] = useState(false)
    const user = useUser()
    const params = useParams()
    const isAdmin = !!user?.scope?.find(a => a === 'admin')

    const resetPassword = () => {
        network.post(`${config.base_path}/api/dashboard/user/${params.id}/password/reset`, {})
            .then(response => {
                setResetPasswordModal(true)
                setDialogBoxMessage(response.data.message)
                toast(response.data.statusCode === 0 ? `New Password: ${response.data.payload}` : "")
                setNewPassword(response.data.statusCode === 0 ? `New Password: ${response.data.payload}` : "")
            })
            .catch(err => {
                setResetPasswordModal(true)
                setDialogBoxMessage(err.message)
            });
    };

    useEffect(() => {
        fetchUser()
    }, [])

    const fetchUser = () => {
        network.get(`${config.base_path}/api/dashboard/user/${params.id}/get`)
            .then(response => {
                if (response.data.payload.length !== 0)
                    setData(response.data.payload)
            })
            .catch(err => console.log(err));
    };

    let row = data;
    if (!row)
        return <div> No Data to show</div>
    return (
        <BasePage>
            <Paper variant={'outlined'} sx={{textAlign: '-webkit-center', padding: '20px'}}>
                <Grid key={row.id} container spacing={1}>
                    <Grid item xs={12}>
                        <Avatar
                            alt={row.name.first}
                            src={`${config.base_path}/api/user/profile/${row.id}/pic`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"}>
                            Name: {row.name.first} {row.name.last}
                            <br/>
                            Username: {row.username}
                            <br/>
                            UserId: {row._id}
                            <br/>
                            {!!row.email && <>Email: {row.email}<br/></>}
                            {!!row.phone_number && <>Phone Number: {row.phone_number}<br/></>}
                            {row.about && <>About: {row.about}<br/></>}
                            Response Time : {row.response_time / 86400000} Days
                            <br/>
                            Gender: {row.gender === 1 ? "Male" : "Female"}
                            <br/>
                            <b>Scope:</b> {row.scope.join(", ")}
                        </Typography>
                    </Grid>
                    <Grid item xs={0} md={2}></Grid>
                    {/* <Grid item xs={12} md={4}>
                            <Button variant={"outlined"}
                                    onClick={() => navigate(`/user/${row.id}/edit/bankdetails`)}>
                                Edit RazorPay ID
                            </Button>
                        </Grid> */}
                    {isAdmin && <Grid item xs={12} md={4}>
                        <Button variant={"outlined"} onClick={() => resetPassword()}>
                            Reset Password
                        </Button>
                    </Grid>}
                    {isAdmin && <Grid item xs={12} md={4} sx={{marginTop: '8px'}}>
                        <Button variant={"outlined"} onClick={() => setOpenUserScope(true)}>
                            Edit Scope
                        </Button>
                    </Grid>}
                    <Grid item xs={12} md={12}>
                        <Button variant={"outlined"} onClick={() => setEditUser(true)}>
                            Edit Profile
                        </Button>
                        {editUser && <EditUserInfo drawerState={editUser} setDrawerState={setEditUser} id={row.id}
                                                   userData={row}/>}
                    </Grid>
                </Grid>
            </Paper>

            {openUserScope && <UserScopeDialog open={openUserScope}
                                               closeBox={() => setOpenUserScope(false)}
                                               dialogData={{userId: row._id}}/>}

            {resetPasswordModal && <DialogBox closeBox={() => setResetPasswordModal(false)}
                                              heading={dialogBoxMessage}
                                              acceptText={'Okay!'}
                                              open={resetPasswordModal}
                                              confirm={() => setResetPasswordModal(false)}
                                              text={newPassword} cancelText={'Cancel'}/>}
        </BasePage>
    )
};
