import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useNavigate, useParams} from "react-router-dom";
import CreateCampaign from "./create";
import {tellSlack} from "../../../../network/apis";
import {toast} from "react-toastify"
import Link from "@mui/material/Link";

export default function ListCampaigns(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()

    async function loadFunc(page) {
        const fellowships = await network.get(`${config.base_path}/api/dashboard/campaign/list/${page}`)
        setHasMore(fellowships.data.payload.length !== 0)
        setItems(fellowships.data.payload)
    }

    async function onSave(mainData, diff) {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/campaign/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited successfully", {autoClose: 1000})
    }

    function Campaign(props) {

        const cUrl = (slug) => {
            if (slug.includes('fellowship_')) {
                const res = slug.split('fellowship_')[1]
                return `fellowship/${res}`
            } else if (slug.includes('class_')) {
                const res = slug.split('class_')[1]
                return `class/${res}`
            }
            else return slug
        }


        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Slug: {props.slug}</Grid>
                        <Link target={'_blank'} href={`https://mugafi.com/lp/${cUrl(props.slug)}`}>
                            Go to page
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} onClick={() => setEditObject(props)}>Edit</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CreateCampaign/>
        <CDataTable
            pageUrl={"/marketing/campaign/list/"}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}>
            {items.map((a, index) => <Campaign key={index} {...a}/>)}
        </CDataTable>
        {!!editObject && <EditFormDrawer onSave={onSave} onClose={() => setEditObject(null)} open={!!editObject}
                                         schema={editObject}/>}
    </BasePage>
}
