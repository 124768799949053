import {Button} from "@mui/material"
import {useState} from "react"
import {Grid, Card} from "@mui/material"
import network from "network"
import config from "config"
import {useParams} from "react-router-dom"
import {toast} from "react-toastify"
import CreateSyncTags from "components/sync/createtags"
import CDataTable from "components/custom/CDataTable"
import {ButtonGroup} from "@mui/material"

export default function TagMapping(props) {
    const [attachment, setAttachment] = useState(false)
    const [items, setItems] = useState([])
    const params = useParams()

    async function loadFunc() {
        const res = await network.get(`${config.base_path}/api/dashboard/asset_tag/list/1?page_size=200&asset_id=${params.asset_id}`)
        setItems(res.data.payload)
    }


    function Tags(props) {
        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Name: <b>{props.name}</b></Grid>
                        <Grid item xs={12}>Type: <b>{props.type}</b></Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} color={"success"}
                                        onClick={() => handleClick(props._id)}>Attach</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    async function handleClick(id) {
        await network.post(`${config.base_path}/api/dashboard/asset_tag_mapping/create`, {
            asset_id: params.asset_id,
            tag_id: id
        })
        setAttachment(false)
        toast.success("Edited Successfully", {autoClose: 1000})
        setInterval(() => window.location.reload(), 1000);
    }

    return <>
        <CreateSyncTags/>
        <Button variant={"outlined"} onClick={() => setAttachment(true)}> Attach Tags</Button>
        {attachment && <CDataTable
            pageUrl={null}
            page={parseInt("1")}
            pageChanged={loadFunc}
            hasMore={null}
            sortBy={'hide'}
            pagination={'hide'}
            items={items.map((a, index) => <Tags key={index} {...a} />)}/>}
    </>
}
