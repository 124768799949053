import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable";
import {toast} from "react-toastify";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import NewReleasesIcon from '@mui/icons-material/NewReleases';


function UserSubmission(props) {
    const params = useParams()

    const onReqAction = async (status) => {
        const action = await network.post(`${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms/${params.room_id}/users/${params.user_id}/submissions/${props.id}`,
            {status: status})
        if (action.data.statusCode === 0) {
            toast(`${props.user.full_name}'s publication status changed to ${status}.`)
            props.pageFunc()
        } else
            toast("There is some error in the request. \n" + action.data.message)
        return
    }

    return <>
        <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    {props.attachments.map((item, index) => <Box key={index}>
                        <p><b>Title: </b>{item.story_metadata.title}</p>
                        <p><b>Summary: </b>{item.story_metadata.summary}</p>
                    </Box>)}
                    <Stack direction={'row'} sx={{maxHeight: '5vh', alignSelf: 'center'}}>
                        {props.status === 'in_review' && <ButtonGroup>
                            <Button variant={"outlined"} color={"success"}
                                    onClick={() => onReqAction("published")}>Publish</Button>
                            <Button variant={"outlined"} color={"error"}
                                    onClick={() => onReqAction("rejected")}>Reject</Button>
                        </ButtonGroup>}
                        {props.status === 'rejected' && <ButtonGroup>
                            <Button variant={"outlined"} color={"success"}
                                    onClick={() => onReqAction("in_review")}>Move to pending</Button>
                        </ButtonGroup>}
                    </Stack>
                </Stack>
            </Card>
        </Grid>
    </>
}

export default function ListSubmission() {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [currentStatus, setCurrentStatus] = useState("draft")
    const params = useParams()

    async function loadFunc(page, status) {
        const submissions = await network.get(`${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms/${params.room_id}/user/${params.user_id}/submissions?page=${page}&status=${status || 'draft'}`)
        setHasMore(submissions.data.payload.length !== 0)
        setItems(submissions.data.payload)
    }

    useEffect(() => {
        loadFunc(1, currentStatus)
    }, [currentStatus])

    return <BasePage>
        <ButtonGroup>
            <Button color={currentStatus === "draft" ? 'success' : 'primary'}
                    onClick={() => setCurrentStatus("draft")}>Draft
            </Button><Button color={currentStatus === "in_review" ? 'success' : 'primary'}
                             onClick={() => setCurrentStatus("in_review")}>Pending</Button>
            <Button color={currentStatus === "published" ? 'success' : 'primary'}
                    onClick={() => setCurrentStatus("published")}>Published</Button>
            <Button color={currentStatus === "rejected" ? 'success' : 'primary'}
                    onClick={() => setCurrentStatus("rejected")}>Rejected</Button>
        </ButtonGroup>
        <p><b>{items[0]?.user?.full_name}</b></p>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/rooms/${params.room_id}/users/${params.user_id}/submissions/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.length > 0 ?
            items.map((a, index) => <UserSubmission key={index} {...a}
                                                           pageFunc={() => loadFunc(params.page, currentStatus)}/>
                ): <Stack direction={'row'} alignItems={'center'}>
                    <NewReleasesIcon />&nbsp;<b>No data available</b>
            </Stack>}/>
    </BasePage>
}