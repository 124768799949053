import React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import {Link} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import config from "../config";
import network from "../network";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import BasePage from "../components/BasePage";
import {ButtonGroup} from "@mui/material";
import Loader from 'components/custom/Loader';
import {useState} from 'react';
import {useParams} from '../../node_modules/react-router-dom/index';
import {useEffect} from 'react';


export default function Home(props) {
    const params = useParams()
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(Number(params.page) || 1)
    const [next, setNext] = useState(false)
    const [loadNext, setLoadNext] = useState(false)
    const [users, setUsers] = useState([])

    const apiCall = (page = currentPage) => {
        network.get(`${config.base_path}/api/celebs/all/${page}`)
            .then((response) => {
                setData(response.data.payload)
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const nextPage = (page = Number(currentPage) + 1) => {
        setLoadNext(true)
        network.get(`${config.base_path}/api/celebs/all/${page}`)
            .then((response) => {
                if (response.data.payload.length === 0) {
                    setNext(true)
                    setLoadNext(false)
                } else {
                    setNext(false)
                    setLoadNext(false)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        apiCall()
    }, [])


    const keyPress = e => {
        if (e.which === 13) {
            if (search !== "") {
                window.location.assign("/user/" + search)
            }
        }
    }

    const onInputChange = (e) => {
        network.get(
            config.base_path + "/api/community/search/members?search=" + e.target.value)
            .then((res) => {
                console.log(res.data.payload)
            })
    }

    return (
        <BasePage>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={7}>
                    <Autocomplete
                        id="search-user"
                        options={users}
                        onInputChange={(event, value) => {
                            setSearch(value)
                        }}
                        onKeyPress={e => keyPress(e)}
                        onInput={(e) => {
                            onInputChange(e)
                        }}
                        getOptionLabel={(option) => option.username}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label=" Search Username..."
                                variant="outlined"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        type="submit"
                        aria-label="search"
                        onClick={() => {
                            if (search !== "") {
                                window.location.assign("/user/" + search)
                            }
                        }}
                        size="large">
                        <SearchIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={2}>

                </Grid>
            </Grid>
            {!!data ? <>
                    {data.map(row => (<List key={row.id} component={Paper} style={{padding: "10px", marginTop: "10px",}}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar alt={row.name}
                                        src={`${config.base_path}/api/user/profile/${row.id}/pic`}/>
                            </ListItemAvatar>
                            <ListItemText primary={
                                <Link
                                    style={{
                                        fontFamily: "Source Sans Pro",
                                        color: "#4D4F5C",
                                        fontSize: "1.1rem",
                                        fontWeight: "bold"
                                    }} to={`user/${row.id}`}>{row.name}</Link>}
                                          secondary={
                                              <Typography
                                                  style={{
                                                      color: "rgba(67, 66, 93, 0.5)",
                                                      fontSize: "14px",
                                                  }}
                                              >
                                                  <span>Username: {row.username}</span>
                                                  <br/>
                                                  {/* <span>Price:  &#x20b9; {row.price}/- </span> */}
                                              </Typography>}/>
                        </ListItem>
                    </List>))}
                    <Grid item container style={{width: "100%"}} justifyContent={"flex-end"}>
                        <ButtonGroup>
                            <Button variant={"outlined"} disabled={currentPage === 1 || loadNext}
                                    onClick={() => {
                                        if (currentPage !== 1) {
                                            nextPage(currentPage)
                                            apiCall(currentPage - 1)
                                            setCurrentPage(currentPage - 1)
                                        }
                                    }}>{currentPage - 1}</Button>
                            <Button disabled>{currentPage}</Button>
                            <Button variant={"outlined"} disabled={next || loadNext}
                                    onClick={() => {
                                        nextPage(currentPage + 2)
                                        apiCall(currentPage + 1)
                                        setCurrentPage(currentPage + 1)
                                    }}>{currentPage + 1}</Button>
                        </ButtonGroup>
                    </Grid>
                </> :
                <Loader/>}
        </BasePage>
    );
};
