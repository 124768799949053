import React, {useEffect, useState} from 'react'
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableRowComponent from '../../components/custom/TableRowComponent';
import network from "../../network";
import config from '../../config';
import RightDrawer from '../../components/custom/RightDrawer';
import ReportedPostForm from '../../components/community/ReportedPostForm';
import {Button} from '@mui/material';
import Grid from '@mui/material/Grid'
import BasePage from "../../components/BasePage";
import { useParams } from '../../../node_modules/react-router-dom/index';
import { ButtonGroup } from '../../../node_modules/@mui/material/index';

const useStyles = makeStyles({
    content: {
        marginTop: "80px",
        marginLeft: "240px",
    },
    table: {
        minWidth: 550,
        fontFamily: "Source Sans Pro",
    },

    pageHeading: {
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.6rem",
        fontWeight: "700",
        marginBottom: "20px"

    }
    ,
    tableHeading: {
        fontWeight: "700",
        fontSize: "1.1rem"
    },
});

const CommunityReportedPosts = (classes) => {


    classes = useStyles();
    let pageSize = 10
    const [reportedPostDrawer, setReportedPostDrawer] = useState(false);
    const [reportedPostData, setReportedPostData] = useState([])
    const [formDataId, setFormDataId] = useState()
    const [formData, setFormData] = useState([])
    const formType = "edit"
    const [currentPage, setCurrentPage] = useState(1);
    const [next, setNext] = useState(true)


    const populateReportedPostData = async (current_page=currentPage) => {

        network.get(`${config.base_path}/api/community/dashboard/posts/reported?page=${current_page}&page_size=${pageSize}`)
            .then((response) => {
                    setReportedPostData(response.data.payload)
                    if(response.data.payload.length === 0)
                    setNext(false)
                }
            )
    }

    const tableData = {
        head: [

            {
                id: 'profilePic',
                label: ''
            },
            {
                id: 'owner',
                label: 'Post Owner'
            },
            {
                id: 'reports_count',
                label: 'Number of Reports'
            },
            {
                id: 'actions',
                label: ''
            }],
        body:
            reportedPostData.map(a => {
                return {

                    id: a.id,
                    profilePic: a.user.profile_pic,
                    owner: a.user.full_name,
                    reports_count: a.reports_count,
                    actions: ['view']
                }
            })


    };


    const getPrevious = () => {

        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }

    }

    const getNext = () => {

        setCurrentPage(currentPage + 1)

    }


    useEffect(() => {
        populateReportedPostData()
    }, [currentPage])


    const viewClickHandler = (id) => {

        console.log("click handler post id : " + id)
        let postData = reportedPostData.find(o => o.id === id);
        setReportedPostDrawer(true)
        setFormDataId(id)
        setFormData(postData)
    }


    return (
        <BasePage>
            <Typography className={classes.pageHeading}>Reported Posts</Typography>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                tableData.head.map((tr) => <TableCell key={tr.id}
                                                                      className={classes.tableHeading}>{tr.label}</TableCell>)
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableData.body.map((row, index) => <TableRowComponent
                            onViewClick={() => viewClickHandler(row.id)} key={index} rowId={index} rowbody={row}
                            rowhead={tableData.head}/>)}
                        <RightDrawer drawerState={reportedPostDrawer} setDrawerState={setReportedPostDrawer}
                                     formComponent={ReportedPostForm} formId={formDataId} formType={formType}
                                     formData={formData}/>
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container style={{
                padding: "20px"
            }}
            >
            <Grid item container style={{ width: "100%" }} justifyContent={"flex-end"}>
            <ButtonGroup>
                            <Button variant={"outlined"} disabled={currentPage === 1}
                                onClick={() => {
                                    if (currentPage !== 1) {
                                        populateReportedPostData(currentPage - 1)
                                        setCurrentPage(currentPage - 1)
                                    }
                                }}>{currentPage - 1}</Button>
                            <Button disabled>{currentPage}</Button>
                            <Button variant={"outlined"} disabled={!next}
                                onClick={() => {
                                    populateReportedPostData(currentPage + 1)
                                    setCurrentPage(currentPage + 1)
                                }}>{currentPage + 1}</Button>
                        </ButtonGroup>
        </Grid>
            </Grid>
        </BasePage>
    )
}

export default CommunityReportedPosts
