import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import {useNavigate, useParams} from "react-router-dom";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify"
import EditIcon from "@mui/icons-material/Edit";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack";
import CreateRoom from "./create";

const EditBox = styled(Box)(() => ({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.04)',}
}))

function FellowShip(props) {
    const navigate = useNavigate()
    const params = useParams()
    const isDeleted = !props.enabled

    return <Grid item xs={12}>
        <Card style={{margin: "8px", padding: "8px"}}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <p style={{color: isDeleted ? 'red' : 'auto'}}>{props.title}</p>
                <Stack direction={'row'} sx={{maxHeight: '5vh', alignSelf: 'center'}}>
                    <Button variant={"outlined"} sx={{marginRight: '40px'}}
                            onClick={() => navigate(`/fellowship/${params.class_id}/rooms/${props.id}/users/1`)}>users</Button>
                    <EditBox onClick={props.onClick}>
                        <EditIcon sx={{margin: '8px', cursor: 'pointer'}} fontSize={'small'}/>
                    </EditBox>
                </Stack>
            </Stack>
        </Card>
    </Grid>
}

export default function ListRooms(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()

    async function loadFunc(page) {
        const fellowships = await network.get(`${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms?page=${page}`)
        setHasMore(fellowships.data.payload.length !== 0)
        setItems(fellowships.data.payload)
    }

    const handleSubmit = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        const res = await network.post(`${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms/${mainData.id}`, diff)
        if (res.data.status !== 0)
            toast.error(res.data.message, {autoClose: 1000})
        else {
            toast.success("Edited Successfully", {autoClose: 1000})
            setEditObject(null)
            loadFunc(params.page)
        }
    }


    return <BasePage>
        <CreateRoom/>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/modules/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <FellowShip key={index} {...a} onClick={() => setEditObject(a)}/>)}/>
        {!!editObject && <EditFormDrawer onClose={() => setEditObject(null)} open={!!editObject} schema={editObject}
                                         onSave={handleSubmit}/>}
    </BasePage>
}
