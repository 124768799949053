import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import {styled} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuGenerator from "./MenuGenerator";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu"
import LocalActivity from "@mui/icons-material/LocalActivity";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ConfirmationNumber from "@mui/icons-material/ConfirmationNumber";
import Equalizer from "@mui/icons-material/Equalizer";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import ListAlt from "@mui/icons-material/ListAlt";
import LocalOffer from "@mui/icons-material/LocalOffer";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import SupervisedUserCircleRounded from "@mui/icons-material/SupervisedUserCircleRounded";
import ThumbsUpDown from "@mui/icons-material/ThumbsUpDown";
import {useUser} from "./UserProvider";
import {Navigate, useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import {useTheme} from "@mui/material/styles";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';


const drawerWidth = "240px";

const StyledMain = styled('main')(({theme}) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth})`,
        marginLeft: drawerWidth
    }
}))

const AppBarSpacer = styled("div")(({theme}) => theme.mixins.toolbar)

const StyledDrawer = styled(Drawer)(({theme}) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: drawerWidth,
    [theme.breakpoints.down('lg')]: {
        backgroundColor: 'unset'
    }
}))

const CAppBar = styled(AppBar)(({theme}) => ({
    fontWeight: 'bolder',
    [theme.breakpoints.up("md")]: {
        marginLeft: drawerWidth,
        width: "unset"
    }
}))

export default function BasePage(props) {

    const user = useUser()
    const navigate = useNavigate();
    const theme = useTheme()

    const [menu, setMenu] = useState({})
    const [mobileOpen, setMobileOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        if (!user.isLoggedIn)
            navigate("/login");
    }, [user.isLoggedIn])

    useEffect(() => {
        if (!user.allowed) return
        const menu = {}
        menu["General"] = menu["General"] || []
        menu["Shoutout"] = menu["Shoutout"] || []
        menu["Unluclass"] = menu["Unluclass"] || []
        menu["Fellowship"] = menu["Fellowship"] || []
        menu["Club"] = menu["Club"] || []
        menu["Settings"] = menu["Settings"] || []
        menu["Sync"] = menu["Sync"] || []
        menu["Marketing"] = menu["Marketing"] || []
        menu["Community"] = menu["Community"] || []
        menu["Work Opportunity"] = menu["Work Opportunity"] || []
        menu["Events"] = menu["Events"] || []
        menu["Third Party"] = menu["Third Party"] || []

        menu["General"].push({title: "Home", icon: <HomeOutlinedIcon/>, url: "/"})

        if (user.allowed.insights.view) {
            menu["General"].push({title: "Insights", icon: <Equalizer/>, url: "/insights"})
        }
        if (user.allowed.users.edit) {
            menu["General"].push({title: "Users", icon: <PersonAddOutlined/>, url: "/user/add"})
        }
        if (user.allowed.tags.edit) {
            menu["General"].push({title: "Tags", icon: <LocalOffer/>, url: "/tags"})
        }
        if (user.allowed.tags.edit) {
            menu["General"].push({title: "File Upload", icon: <DriveFolderUploadIcon/>, url: "/file_upload"})
        }
        if (user.allowed.shoutout.edit) {
            menu["Shoutout"].push({title: "Requests", icon: <ListAlt/>, url: "/shoutout/requests/1"})
        }
        if (user.allowed.unluclass.edit) {
            menu["Unluclass"].push({title: "orders", icon: <LibraryBooks/>, url: "/unluclass/orders"})
            menu["Unluclass"].push({title: "manage", icon: <LibraryBooks/>, url: "/unluclass"})
        }
        if (user.allowed.fellowship.edit) {
            menu["Fellowship"].push({title: "orders", icon: <ThumbsUpDown/>, url: "/fellowship/orders"})
            menu["Fellowship"].push({
                title: "applications",
                icon: <ThumbsUpDown/>,
                url: "/fellowship/applications"
            })
            menu["Fellowship"].push({
                title: "manage",
                icon: <ThumbsUpDown/>,
                url: "/fellowship/list/1"
            })
        }
        if (user.allowed.house.edit) {
            // menu["House"].push({title: "orders", icon: <ThumbsUpDown/>, url: "/house/orders"})
            // menu["House"].push({
            //     title: "applications",
            //     icon: <ThumbsUpDown/>,
            //     url: "/house/applications"
            // })
            menu["Club"].push({
                title: "manage",
                icon: <ThumbsUpDown/>,
                url: "/house/list/1"
            })
        }
        if (user.allowed.settings.edit) {
            menu["Settings"].push({title: "manage", icon: <SettingsIcon/>, url: "/settings/1"})
        }
        if (user.allowed.sync.edit) {
            menu["Sync"].push({title: "Manage Uploads", icon: <SyncIcon/>, url: "/sync/artists/1"})
            menu["Sync"].push({title: "Groups", icon: <SyncIcon/>, url: "/sync/groups/1"})
            menu["Sync"].push({title: "Tags", icon: <SyncIcon/>, url: "/sync/tags/1"})
            menu["Sync"].push({title: "Quotes and Purchases", icon: <SyncIcon/>, url: "/sync/requestquotes"})
        }
        if (user.allowed.coupons.edit) {
            menu["Marketing"].push({title: "Coupons", icon: <ConfirmationNumber/>, url: "/marketing/coupons"})
        }
        if (user.allowed.campaigns.edit) {
            menu["Marketing"].push({title: "Campaigns", icon: <ConfirmationNumber/>, url: "/marketing/campaign/list/1"})
        }
        if (user.allowed.community.edit) {
            // menu["Community"].push({
            //     title: "Members",
            //     icon: <SupervisedUserCircleRounded/>,
            //     url: "/community/member"
            // })
            menu["Community"].push({title: "Skills", icon: <SupervisedUserCircleRounded/>, url: "/community/skills/1"})
            menu["Community"].push({
                title: "Interests",
                icon: <SupervisedUserCircleRounded/>,
                url: "/community/interests/1"
            })
            menu["Community"].push({title: "Spaces", icon: <SupervisedUserCircleRounded/>, url: "/community/space"})
            menu["Community"].push({
                title: "Post Reports",
                icon: <SupervisedUserCircleRounded/>,
                url: "/community/posts/reported"
            })
            menu["Community"].push({title: "Live", icon: <SupervisedUserCircleRounded/>, url: "/community/live/list/1"})
        }
        if (user.allowed.works.edit) {
            menu["Work Opportunity"].push({title: "manage", icon: <SettingsIcon/>, url: "/works/1"})
        }
        if (user.allowed.events?.edit) {
            menu["Events"].push({title: "manage", icon: <SettingsIcon/>, url: "/events/1"})
        }
        if (user.allowed.thirdparty.bookmyshow) {
            menu["Third Party"].push({title: "BookMyShow", icon: <LocalActivity/>, url: "/thirdparty/bookmyshow"})
        }

        setMenu(menu)

    }, [user.allowed])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!user.isLoggedIn)
        return <Navigate to={"/login"}/>

    return <article>
        <header>
            <CAppBar position="relative">
                <Toolbar>
                    {(window.location.pathname === '/') ? null :
                        <Button aria-controls="simple-menu" aria-haspopup="true" style={{color: "#fff"}}
                                onClick={() => history.back()}><ArrowBackIcon/>Go Back</Button>}
                    <div style={{flexGrow: 1}}>
                        {matchMobile &&
                            <IconButton aria-label="open drawer" edge="start" onClick={() => setMobileOpen(!mobileOpen)}
                                        size="small">
                                <MenuIcon/>
                            </IconButton>}
                    </div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" style={{color: "#fff"}}
                            onClick={handleClick}>@{user.profile?.username}</Button>
                    <Menu id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}>
                        <MenuItem value={user.profile?.username} disabled>
                            @{user.profile?.username}
                        </MenuItem>
                        <MenuItem value={user.profile?.username}
                                  onClick={() => user.logout()}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </CAppBar>
            <nav aria-label="Menu">
                <StyledDrawer
                    open={matchMobile ? mobileOpen : true}
                    variant={matchMobile ? 'temporary' : 'permanent'}
                    anchor="left"
                    onClose={() => setMobileOpen(!mobileOpen)}
                    ModalProps={{keepMounted: true}}
                    PaperProps={{style: {width: drawerWidth}}}
                >
                    <AppBarSpacer>
                        <img src={"/Logo.png"} style={{width: "80px", padding: "8px", paddingLeft: "16px"}}
                             alt={"Unlu"}/>
                    </AppBarSpacer>
                    <MenuGenerator data={menu}/>
                </StyledDrawer>
            </nav>
        </header>
        <StyledMain>
            <Box style={{margin: '0 auto', padding: "16px"}} alignItems={'center'} justifyContent={'center'}
                 maxWidth={'xl'}>
                {props.children}
            </Box>
        </StyledMain>
    </article>
}
