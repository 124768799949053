import React, {useEffect, useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify"
import Link from "@mui/material/Link";
import {ButtonGroup} from "@mui/material";
import Stack from "@mui/material/Stack";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

function User(props) {
    const params = useParams()
    const navigate = useNavigate()

    const onReqAction = async (status) => {
        const accept = await network.post(`${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms/${params.room_id}/users/${props.user.id}`,
            {status: status})
        if (accept.data.statusCode === 0) {
            toast(status === 1 ? `${props.user.full_name} added to the room.` : `Rejected ${props.user.full_name}'s request for the room`)
            props.pageFunc()
        } else
            toast("There is some error in the request. \n" + accept.data.message)
    }

    const onRemoveUser = async () => {
        if (window.confirm('Are you sure?') === true)
            await onReqAction(5)
    }

    return <>
        <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}><b>{props.user.full_name}</b>
                        </Grid>
                        <Grid item xs={12} sx={{fontSize: '13px'}}>
                            <Link>{props.attachments[0].url}</Link>
                        </Grid>
                    </Grid>

                    {props.status === 2 && <ButtonGroup>
                        <Button variant={"outlined"} color={"success"}
                                onClick={() => onReqAction(1)}>Accept</Button>
                        <Button variant={"outlined"} color={"error"}
                                onClick={() => onReqAction(5)}>Reject</Button>
                    </ButtonGroup>}
                    {props.status === 1 && <ButtonGroup>
                        <Button variant={"outlined"} color={"success"}
                                onClick={() => navigate(`/fellowship/${params.class_id}/rooms/${params.room_id}/users/${props.user.id}/submissions/1`)}>
                            User's Submissions</Button>
                        <Button variant={"outlined"} color={"error"}
                                onClick={() => onRemoveUser()}>Remove user</Button>
                    </ButtonGroup>}
                </Grid>
            </Card>
        </Grid>
    </>
}

export default function ListUsers(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()
    const [currentStatus, setCurrentStatus] = useState(2)

    async function loadFunc(page, status) {
        const users = await network.get(`${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms/${params.room_id}/users?page=${page}&status=${status || 2}`)
        setHasMore(users.data.payload.length !== 0)
        setItems(users.data.payload)
    }

    useEffect(() => {
        loadFunc(1, currentStatus)
    }, [currentStatus])

    return <BasePage>
        <ButtonGroup>
            <Button color={currentStatus === 2 ? 'success' : 'primary'}
                    onClick={() => setCurrentStatus(2)}>Requested</Button>
            <Button color={currentStatus === 1 ? 'success' : 'primary'}
                    onClick={() => setCurrentStatus(1)}>Accepted</Button>
            <Button color={currentStatus === 5 ? 'success' : 'primary'}
                    onClick={() => setCurrentStatus(5)}>Rejected</Button>
        </ButtonGroup>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/rooms/${params.room_id}/users/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.length > 0 ?
                items.map((a, index) => <User key={index} {...a}
                                              pageFunc={() => loadFunc(params.page, currentStatus)}/>)
                : <Stack direction={'row'} alignItems={'center'}>
                    <NewReleasesIcon/>&nbsp;<b>No data available</b>
                </Stack>}/>
    </BasePage>
}
