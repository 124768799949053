import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from "@mui/material/Stack";
import network from "../network";
import config from "../config";
import {toast} from "react-toastify";

export default function UserScopeDialog(props) {
    const {open, closeBox, dialogData} = props
    const theme = useTheme();
    const mdDownScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [scopeOptions, setScopeOptions] = useState(null)
    const [checked, setChecked] = useState([])

    useEffect(() => {
        network.get(`${config.base_path}/api/dashboard/user/auth_scopes`).then(a => setScopeOptions(a.data.payload))
    }, [])

    const handleClose = () => {
        closeBox()
    };

    const onConfirmClick = () => {
        network.post(`${config.base_path}/api/dashboard/user/${dialogData.userId}/scope/edit`, {scope: checked})
            .then((response) => {
                if (response.data.statusCode === 0) {
                    toast.success("Scope Edited", {autoClose: 2000});
                    window.location.reload();
                } else {
                    toast.error(response.data.message, {autoClose: 3000});
                }
            })
            .catch((err) => console.log(err));
    };

    const handleChange = (event, a) => {
        let check = event.target.checked;
        if (check) {
            setChecked([...checked, a])
        } else {
            let index = checked.indexOf(a);
            if (index > -1) {
                checked.splice(index, 1);
                setChecked(checked)
            }
        }
    }

    useEffect(() => {
        setChecked(checked)
    }, [checked])

    return <Dialog
        fullScreen={mdDownScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
    >
        <Stack sx={{padding: '32px'}}>
            <DialogTitle id="responsive-dialog-title">User Scope</DialogTitle>
            <DialogContent>
                <FormGroup>
                    {/*<FormControlLabel control={<Checkbox defaultChecked/>} label="Label"/>*/}
                    {scopeOptions?.map((a, index) => <FormControlLabel key={index}
                                                                       control={<Checkbox
                                                                           onChange={(e) => handleChange(e, a)}/>}
                                                                       label={a}/>)}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} onClick={() => onConfirmClick()} color="primary" autoFocus
                        disabled={!!(checked.length === 0)}>
                    Confirm
                </Button>
                <Button variant={'outlined'} autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Stack>
    </Dialog>
}
