import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify"
import FileCopyIcon from "@mui/icons-material/FileCopy";
import fileDownload from "js-file-download";
import Link from "@mui/material/Link";
import {FormControl} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import PublishIcon from "@mui/icons-material/Publish";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";


function UnluEvent(props) {
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))
    async function onSendMailClick() {
        const text = `Do you want to send email to ${props?.unlu_participant?.name.first || props?.guest_participant?.name?.first}?`;
        if (window.confirm(text) === true) {
            const res = await network.post(`${config.base_path}/api/dashboard/unlu_events/${props.event_id}/user/${props.user_id}/send_purchase_mail`, {})
            if (res.data.statusCode === 0)
                toast.success('Mail has been sent', {autoClose:2000})
            else toast.error(res.data.message, {autoClose:2000})
        }
    }

    return <Grid item xs={12}>
        <Card style={{
            margin: "8px",
            padding: "18px",
            alignItems: 'center',
            display: 'flex',
            flexDirection: matchMobile ? 'column' : 'row',
            justifyContent: 'space-between'
        }}>
            <Box>
                <p><b style={{color: '#1976d2'}}>{props?.order_id ? 'paid' : ''}</b></p>
                {!props.is_guest ?
                    <p>Name: <b>{props?.unlu_participant?.name.first} {props?.unlu_participant?.name?.last}</b></p> :
                    <p>Name: <b>{props?.guest_participant?.name?.first} {props?.guest_participant?.name?.last}<b
                        style={{color: '#1976d2'}}> {`/ Alien`}</b></b></p>}
                {props?.attachments[0]?.url && <p>Attachment: <Link rel="stylesheet" href={props?.attachments[0]?.url}
                                                                    target={'_blank'}>{props?.attachments[0]?.url}</Link>
                </p>}
                {props?.guest_participant && <><p>Email: <b>{props?.guest_participant?.email}</b></p>
                    <p>phone_number: <b>{props?.guest_participant?.phone_number}</b></p></>}
                {props?.unlu_participant && <><p>username: <b>{props?.unlu_participant?.username}</b></p></>}
            </Box>
            {props?.order_id && <Button variant={'outlined'} onClick={() => onSendMailClick()}>Send purchase mail</Button>}
        </Card>
    </Grid>
}

export default function EventRegistrations(props) {
    const params = useParams()
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [pageSize, setPageSize] = useState(params.page_size || 10)
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    async function loadFunc(page) {
        const work = await network.get(`${config.base_path}/api/dashboard/unlu_events/${params.event_id}/list/${page}?page_size=${pageSize}`)
        setHasMore(work.data.payload.length !== 0)
        setItems(work.data.payload)
    }

    function exportData(copy) {
        const csvString = [
            [
                'no.',
                'id',
                'name',
                'username',
                'email',
                'phone_number',
                'attachments',
                'payment order_id',
                'payment status',
                'enrolled_for_event_id'
            ],
            ...items.map((item, index) => [
                index + 1,
                item?.id,
                item?.is_guest ? item?.guest_participant?.name?.first + item?.guest_participant?.name?.last : item.unlu_participant?.name?.first + item.unlu_participant?.name?.last,
                item?.is_guest ? item?.guest_participant?.username : item?.unlu_participant?.username,
                item?.is_guest ? item?.guest_participant?.email : item?.unlu_participant?.username,
                item?.is_guest ? item?.guest_participant?.phone_number : item?.unlu_participant?.username,
                item?.attachments[0]?.url,
                item?.order_id,
                item.order_id ? 'paid' : 'unpaid',
                item?.event_id
            ])
        ].map(e => e.map(a => `"${a}"`).join(",")).join("\n")
        if (copy) {
            navigator.clipboard.writeText(csvString)
            toast('CSV data is copied to the clipboard')
            return
        }
        fileDownload(csvString, 'contestRequestData.csv')
    }

    return <BasePage>
        <Stack direction={matchMobile ? 'column' : 'row'} spacing={2} sx={{justifyContent: 'flex-end'}}>
            <FormControl>
                <TextField style={{minWidth: '60px'}}
                           required
                           value={pageSize}
                           id="pageSize"
                           variant="outlined"
                           label={'No of Records'}
                           onChange={(e: any) => setPageSize(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <IconButton edge="end" color="primary">
                                           <SendIcon onClick={() => {
                                               loadFunc(params.page)
                                           }}/>
                                       </IconButton>
                                   </InputAdornment>

                           }}/>
            </FormControl>
            <Button
                onClick={() => exportData(true)}
                variant="contained"
                color="secondary"
            >
                <FileCopyIcon style={{height: "1.25vw"}}/> Copy CSV
            </Button>
            <Button
                onClick={() => {
                    exportData(false);
                }}
                variant="contained"
                color="primary"
            >
                <PublishIcon/> Export
            </Button>
        </Stack>
        <CDataTable
            pageUrl={`/events/${params.event_id}/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <UnluEvent key={index} {...a}/>)}/>
    </BasePage>
}
