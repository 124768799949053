import React, {useEffect, useState} from 'react'
import {Button, Paper, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import network from "../../network";
import config from "../../config";
import qs from 'querystring'


const useStyles = makeStyles({
    formLabel: {

        marginTop: "10px",
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.1rem"

    },
    textField: {
        width: '22ch',
    },

    submitBtn: {
        marginTop: "40px",
        width: "48%",
        margin: "0 1%"

    }
});


const ReportedPostForm = (props) => {

    const classes = useStyles();
    const [postBody, setPostBody] = useState();
    const remark = "none"


    // const populateData = () => {

    //     console.log(props.formData)
    //     // setPostBody(props.formData.body)
    // }


    // useEffect(() => {
    //     populateData()
    // }, [])


    const handleSubmit = (type) => {

        if (window.confirm("Are you sure to " + type + " the post?")) {

            let actionType
            if (type === "ignore") {
                actionType = "ignore_post"
            } else if (type === "delete") {
                actionType = "delete_post"

            }


            network.post(config.base_path + `/api/community/dashboard/posts/${props.formId}/resolve`,
                qs.stringify({action: actionType, remark: remark})
            )
                .then((response) => {
                    if (response.data.statusCode === 0) {
                        alert("Post " + type + "ed successfully!")
                        window.location.reload();
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(err => console.log(err));
        }
    }


    const attachmentComponent = () => {

        if (props.formData.attachments[0].type === "image") {
            return <img src={props.formData.attachments[0].file_url} width="250" height="250"/>

        } else if (props.formData.attachments[0].type === "audio") {
            return <audio src={props.formData.attachments[0].file_url} controls/>

        } else if (props.formData.attachments[0].type === "video") {

            return <video width="350" height="350" controls>
                <source src={props.formData.attachments[0].file_url} type="video/mp4"/>
            </video>

        } else if (props.formData.attachments[0].type === "document" || props.formData.attachments[0].type === "link") {
            return <a href={props.formData.attachments[0].file_url} target="_blank">Attachments/Link</a>

        }


    }


    return (
        <Paper style={{padding: "60px 20px", minWidth: "500px", maxWidth: "500px", height: "100%"}}>
            <Grid>
                <h4>Reported Post</h4>
            </Grid>

            <Paper style={{margin: "20px 0px", padding: "20px", width: "100%"}} variant="outlined">
                <Grid container direction='row' alignItems="flex-start">
                    <Grid item xs={2}>
                        <img src={props.formData.user.profile_pic}
                             style={{width: 50, height: 50, borderRadius: 50 / 2}}/>
                    </Grid>
                    <Grid item xs={10}>
                        <div style={{display: "flex", alignItems: "baseline"}}>
                            <Typography style={{
                                fontFamily: "Circular Std",
                                color: "#090612",
                                fontSize: "16px",
                                fontWeight: 900
                            }}>
                                {props.formData.user.full_name}
                            </Typography>
                            <Typography>
                                &nbsp;
                            </Typography>
                            < Typography style={{
                                fontFamily: "Circular Std",
                                color: "#CA5C3F",
                                fontSize: "14px",
                                borderRadius: "48px",
                                backgroundColor: "#FFF0EC",
                                padding: "5px",
                                textTransform: "uppercase"
                            }}>
                                {props.formData.user.labels[0]}
                            </Typography>

                        </div>
                        <div>
                            <Typography style={{
                                fontFamily: "Circular Std",
                                color: "#090612",
                                fontSize: "14px",
                                opacity: 0.6,
                                textTransform: "uppercase"
                            }}>
                                {props.formData.user.mini_about}
                            </Typography>
                        </div>
                    </Grid>
                    <Typography style={{margin: "10px"}}> {props.formData.body}</Typography>
                </Grid>

                {(props.formData.attachments.length) ? attachmentComponent() : null}

            </Paper>
            <Button className={classes.submitBtn} variant="contained" color="success"
                    onClick={() => handleSubmit("ignore")}>Ignore</Button>
            <Button className={classes.submitBtn} variant="contained" color="error"
                    onClick={() => handleSubmit("delete")}>Delete</Button>
        </Paper>
    )
}

export default ReportedPostForm
