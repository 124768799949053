import React, { useState, useEffect } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import PublishIcon from '@mui/icons-material/Publish';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack"

const fileDownload = require('js-file-download');

export default function ListEnrollments(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()
    const [multiSelect, setMultiSelect] = useState(false)
    const [selectAll, setSelectAll] = useState(true)
    const [isSelect, setIsSelect] = useState([])
    const [pageSize, setPageSize] = useState(10)

    async function loadFunc(page) {
        const enrollment = await network.get(`${config.base_path}/api/dashboard/enrollments/${params.class_id}/${page}/get?page_size=${pageSize}`)
        setHasMore(enrollment.data.payload.length !== 0)
        setItems(enrollment.data.payload)
    }
    const onSendCertificate = async (certificate) => {
        const payload = { user_ids: certificate ? [certificate] : isSelect.map(i => i.id) }
        await network.post(`${config.base_path}/api/dashboard/unluclass/${params.class_id}/send_certificate`, payload)
            .then(response => {
                if (response.data.statusCode === 0) {
                    toast("certificate sent")
                    // window.location.reload();
                }
                else {
                    toast.error("can not send certificate: " + response.data.message)
                }
            })
            .catch(err => {
                console.error(err)
            });
    }

    useEffect(() => {
        console.log('selected', isSelect)
    }, [isSelect])

    function EnrollUser(props) {
        const data = props.user || props.user_mini
        const onRemove = async (index) => {
            const list = isSelect
            const index1 = list.indexOf(index)
            list.splice(index1, 1)
            setIsSelect(list)
            setIsSelect([...isSelect])
        }

        const onClickHandle = (index) => {
            if (multiSelect)
                setIsSelect([...isSelect, index])
            else return;
        }

        return <Grid item xs={12}>
            {data && <Card style={{
                margin: "8px",
                padding: "8px",
                cursor: multiSelect ? 'pointer' : '',
                background: (isSelect.includes(data) && multiSelect) ? 'linear-gradient(135deg, #fcdf8a 0%,#f38381 100%)' : ''
            }}
                   onClick={() => (isSelect.includes(data) && multiSelect) ? onRemove(data) : onClickHandle(data)}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Name: &nbsp;<b>{data?.name.first} {data?.name.last}</b></Grid>
                        <Grid item xs={12}>Username: &nbsp;<b>{data?.username}</b></Grid>
                        <Grid item xs={12}>User ID: &nbsp;<b>{data?.id}</b></Grid>
                        <Grid item xs={12}>Progress: &nbsp;<b>{props?.progress}</b></Grid>
                        {data?.email && <Grid item xs={12}>Email: &nbsp;<b>{data?.email}</b></Grid>}
                        {data.phone_number && <Grid item xs={12}>phone_number: &nbsp;<b>{data.phone_number}</b></Grid>}
                    </Grid>
                    <Button variant="outlined" sx={{color: '#5053EC', borderColor: '#5053EC'}}
                            onClick={() => onSendCertificate(data.id)}
                            disabled={isSelect.includes(data)}>
                        {props.certificate_claimed ? 'Resend' : 'send certificate'}
                    </Button>
                </Grid>
            </Card>}
        </Grid>
    }

    const handleChange = () => {
        setMultiSelect(!multiSelect)
    }

    const onSelect = () => {
        if (isSelect.length > 0)
            setIsSelect([])
        else
            setIsSelect([...isSelect, items.map(item => [item?.user || item?.user_mini])])
    }
    function exportData(copy) {
        const csvString = [
            [
                'user_id',
                'name',
                'username',
                'enrolled_for'
            ],
            ...items.map(item => [
                item.user_mini.id,
                item.user_mini.name.first + ' ' + item.user_mini.name.last,
                item.user_mini.username,
                item.enrolled_for
            ])
        ].map(e => e.map(a => `"${a}"`).join(",")).join("\n")
        if (copy) {
            navigator.clipboard.writeText(csvString)
            toast('CSV data is copied to the clipboard')
            return
        }
        fileDownload(csvString, 'requestData.csv')
    }

    return <BasePage>
        <Checkbox  onChange={handleChange} />Select Multiple
        {multiSelect ? <Grid>
            <Button variant="contained" color="primary" onClick={() => onSendCertificate(null)}>Send certificate</Button>
        </Grid>
            :
            <Stack style={{ marginTop: '10px'}} direction={'row'}>
                <TextField
                           required
                           value={pageSize}
                           label="No of Records"
                           id="pageSize"
                           variant="outlined"
                           onChange={(e: any) => setPageSize(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <IconButton edge="end" color="primary">
                                           <SendIcon onClick={() => {
                                               loadFunc(1)
                                           }}/>
                                       </IconButton>
                                   </InputAdornment>
                           }}
                />
                <Button onClick={() => exportData(false)} variant="contained" color="primary"
                    style={{ marginLeft: '2vw' }}>
                    <PublishIcon style={{ height: '1.25vw' }} /> Export
                </Button>
                <Button variant="contained" color="primary"
                    onClick={() => exportData(true)} style={{ marginLeft: '2vw' }}>
                    <FileCopyIcon style={{ height: '1.25vw' }} /> Copy CSV
                </Button>
            </Stack>
        }
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/enrollments/list/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <EnrollUser key={index} {...a} />)} />
    </BasePage>
}
