import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify"
import Create from "components/custom/create";
import {useNavigate} from "react-router-dom/index";


function WorkOppo(props) {
    const navigate = useNavigate()
    return <Grid item xs={12}>
        <Card style={{margin: "8px", padding: "8px", alignItems: 'center'}}>
            <Grid container direction={"row"}>
                <Grid item style={{flexGrow: 1}}>
                    <Grid item xs={12}>Title: <b>{props?.title}</b></Grid>
                    <Grid item xs={12}>Assignment Details: {props?.assignment_details.desc}</Grid>
                    <Grid item xs={12}>ArtformId: {props?.artform_id}</Grid>
                </Grid>
                <Grid item xs={12} md={"auto"}>
                    <Grid item xs={12}>
                        <Button variant={"outlined"} color={"primary"} onClick={() => navigate(`/works/${props._id}/assignments/1`)}>Assignment</Button>
                        <Button variant={"outlined"} color={"secondary"} onClick={() => navigate(`/works/${props._id}/applications/1`)}>Applications</Button>
                        <Button variant={"outlined"} color={"success"} onClick={props.onClick}>Edit</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    </Grid>
}

export default function Works(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()

    async function loadFunc(page) {
        const work = await network.get(`${config.base_path}/api/dashboard/work_opportunity/list/${page}`)
        setHasMore(work.data.payload.length !== 0)
        setItems(work.data.payload)
    }

    async function handleClick(mainData, diff) {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/work_opportunity/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }

    return <BasePage>
        <Grid display={"flex"}>
            <Create id={"work_opp"} title={'Work Opportunity'} slug={'work_opportunity'}/>
        </Grid>
        <CDataTable
            pageUrl={`/works/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <WorkOppo key={index} {...a} onClick={() => setEditObject(a)}/>)}/>
        {!!editObject &&
            <EditFormDrawer id='works' title='Edit Works' onSave={handleClick} onClose={() => setEditObject(null)}
                            open={!!editObject}
                            schema={editObject}/>}
    </BasePage>
}
