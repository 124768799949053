import React, { useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import CreateAssignments from "../create";
import EditFormDrawer from "components/custom/EditFormDrawer";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { tellSlack } from "network/apis";



export default function ListAssignmentUnluClass() {
    const [item, setItem] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc(page) {
        const assignment = await network.get(`${config.base_path}/api/dashboard/assignments/list/${page}?resource_type=unluclass&resource_id=${params.class_id}`)
        setHasMore(assignment.data.payload.length !== 0)
        setItem(assignment.data.payload)
    }

    const onSave = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/assignments/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", { autoClose: 1000 })
    }


    function Assignment(item) {

        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>Title: &nbsp;<b>{item.title}</b></Grid>
                        <Grid item xs={12}>desc: &nbsp;{item.desc}</Grid>
                        <Grid item xs={12}>Resource Type: &nbsp;{item.resource_type}</Grid>
                        <Grid item xs={12}>Resource ID: &nbsp;{item.resource_id}</Grid>
                    </Grid>
                    <Grid item>
                        <Button variant={"outlined"} style={{ marginRight: '20px' }} onClick={() => navigate(`/fellowship/${params.class_id}/assignments/${item._id}/questions/1`)}>Questions</Button>
                        <Button variant={"outlined"} color={"success"} onClick={() => setEditObject(item)}>Edit</Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CreateAssignments />
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/assignments/list/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={item.map((a, index) => <Assignment key={index} {...a} />)} />
        {!!editObject && <EditFormDrawer
            id='assignments'
            title={"Edit Assignment"}
            onSave={onSave}
            onClose={() => setEditObject(null)}
            open={!!editObject}
            schema={editObject} />}
    </BasePage>
}
