function overrideType(id, value) {
    switch (id) {
        case 'work_opp':
        case 'works': {
            switch (value) {
                case 'employer_type':
                    return 'dropDown'
                case 'job_type':
                    return 'dropDown'
                case 'work_location_type':
                    return 'dropDown'
                case 'employer_id':
                    return 'unlu-user'
                case 'artform_id':
                    return 'artform-type'
            }
        }
        case 'sync-groups-asset': {
            switch(value) {
                case 'asset_id':
                    return 'asset-type'
            }
        }
        case 'unlulive_acl' :
        case 'unlulive' : {
            switch (value) {
                case 'resource_id':
                case 'user_id':
                    return 'unlu-user'
                case 'type':
                    return 'meeting-type-dropDown'
                case 'resource_type':
                    return 'meeting-resource-type'
                case 'role':
                    return 'meeting-role'
                default:
                    return ''
            }
        }
        case 'assignments' : {
            switch (value) {
                case 'resource_type':
                    return 'dropDown'
            }
        }
        case 'episode' : {
            switch(value) {
                case 'class_id':
                    return 'unluclass-type'
                case 'type':
                    return 'dropDown'
            }
        }
        default:
            return ''
    }
    return ""
}

function dropdownOption(id, value) {
    switch (id) {
        case 'work_opp':
        case 'works': {
            switch (value) {
                case 'employer_type':
                    return ['individual', 'corporate']
                case 'job_type':
                    return ['project', 'contest', 'gig_work', 'internship']
                case 'work_location_type':
                    return ['remote', 'office', 'hybrid']
                case 'employer_id':
                    return ''
            }
        }
        case 'unlulive' : {
            switch (value) {
                case 'type':
                    return ['MEETING_ONE_TO_MANY','MEETING_MANY_TO_MANY']
            }
        }
        case 'unlulive_acl': {
            switch (value) {
                case 'resource_type':
                    return ['RESOURCE_UNLUCLASS_ENROLLMENT', 'RESOURCE_USER', 'RESOURCE_ALL_USERS']
                case 'role':
                    return ['ROLE_NOT_ALLOWED', 'ROLE_HOST', 'ROLE_PARTICIPANT']
            }
        }
        case 'assignments' : {
            switch (value) {
                case 'resource_type':
                    return ['module', 'work_opportunity']
                default:
                    return ''
            }
        }
        case 'episode' : {
            switch(value) {
                case 'type':
                    return ['live', 'recorded', 'assignment', 'workbook']
            }
        }
        default:
            return []
    }
    return []
}

export {overrideType, dropdownOption}

