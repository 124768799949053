import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import {Button} from "@mui/material/index";
import {useNavigate} from "react-router-dom";


export default function ListAssignmentResponses() {
    const [item, setItem] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc() {
        const question = await network.get(`${config.base_path}/api/dashboard/assignments/${params.assignment_id}/responses?page=${params.page}`)
        setHasMore(question.data.payload.length !== 0)
        setItem(question.data.payload)
    }


    function Assignment(item) {

        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Name: &nbsp;<b>{item.user?.name?.first + item.user?.name?.last}</b></Grid>
                        <Grid item xs={12}>Username: &nbsp;{item.user.username}</Grid>
                    </Grid>
                    <Grid item>
                        <Button variant={'outlined'}
                                onClick={() => alert('ask satyendra to complete this')}>
                            mark completed
                        </Button>
                        <Button variant={"outlined"}
                                onClick={() => navigate(`/fellowship/${params.class_id}/assignments/${params.assignment_id}/users/${item?.user?.id}/1`)}>
                            User's Response</Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/assignments/${params.assignment_id}/responses/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={item.map((a, index) => <Assignment key={index} {...a} />)}/>
    </BasePage>
}
